@import '../../styles/mediamixins.scss';
@import '../../styles/colors.scss';

.congrats-page-container {
  position: relative;
}

.congrats-text {
  font-size: 2.5rem;
}

.congrats-img {
  height: 50vh;
}

.coi-buttons {
  margin-right: 3vw;
}

.no-coi-width {
  width: 250px;
}

.no-coi-paper {
  width: fit-content;
}

.congrats-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.con-background {
  background-image: url('../../assets/congrars_back.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.con-background2 {
  background-image: url('../../assets/congrars_back2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.sky-logo-congrats {
  height: 60px;
}

.global-logo-congrats {
  height: 50px;
}

.underwriter-bg-img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: -3px;
  background-image: url('../../assets/plane-background-image.jpg');
  @include media('<=tablet') {
    background-color: #fafdfe;
    background-image: url('../../assets/plane-background-image-mobile.jpg');
  }
}

.underwriter-main-title {
  font-size: 3.6em;
  margin-left: 70px;
  color: white;
}

.declination-text {
  margin: 29px 110px 0 80px;
}

@include media('<=tablet') {
  .congrats-text {
    font-size: 2rem;
    // max-width: 280px;
    margin-top: -10px !important;
  }

  .congrats-img {
    height: 40vh;
    max-width: 100%;
    object-fit: contain;
  }

  .congrats-page-container {
    // background: linear-gradient(180deg, white 0%, #FAFDFE 36%, $iceblue 100%);
    // height: 102vh;
  }
  .con-background {
    background-image: url('../../assets/congrars_back.png');
    background-size: 300% 100%;
    background-repeat: no-repeat;
  }

  .con-background2 {
    background-image: url('../../assets/congrars_back2.png');
    background-size: 300% 100%;
    background-repeat: no-repeat;
  }
  .sky-logo-congrats {
    height: 30px;
  }

  .global-logo-congrats {
    height: 40px;
  }
}
