@import '../../styles//colors.scss';

.cookiecontainer {
  background: linear-gradient(to bottom, $blue -65%, $darkblue 140%) !important;
  color: $secondary !important;
  z-index: 1999999999 !important;
}

.cookiebtn {
  background-color: $secondary !important;
  color: $blue !important;
  border-radius: 5px !important;
  min-height: 31px !important;
  min-width: 100px !important;
}
