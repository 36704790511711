// function to convert px to em
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
  @if $mq2 == false {
    @media ($maxmin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}

// responsive font size mixin
@mixin font-size-map($font-size-map) {
  @each $breakpoint, $font-size in $font-size-map {
    @if $breakpoint == null {
      font-size: em($font-size) !important;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: em($font-size) !important;
      }
    }
  }
}

@mixin width-size-map($size-map) {
  @each $breakpoint, $size in $size-map {
    @if $breakpoint == null {
      min-width: em($size);
    } @else {
      @include breakpoint($breakpoint) {
        min-width: em($size);
      }
    }
  }
}

// breakpoint variables
$lg: 1170px;
$md: 1024px;
$sm: 640px;
$xs: 480px;

// body font size
$browser-context: 16;

$tiny-font-size: (
  null: 12,
  $md: 12,
  $sm: 11,
  $xs: 11,
);
$small-font-size: (
  null: 14,
  $md: 14,
  $sm: 13,
  $xs: 13,
);
$secondary-font-size: (
  null: 16,
  $md: 16,
  $sm: 15,
  $xs: 15,
);
$body-font-size: (
  null: 18,
  $md: 17,
  $sm: 16,
  $xs: 15,
);
$title-font-size: (
  null: 22,
  $md: 21,
  $sm: 20,
  $xs: 19,
);
$promotion-size: (
  null: 32,
  $md: 32,
  $sm: 28,
  $xs: 26,
);
$button-width: (
  null: 250,
  $lg: 250,
  $md: 140,
  $sm: 140,
  $xs: 130,
);
